import React, {useState, useEffect}from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/LeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { json, useNavigate,useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';


function Createshipment() {
    let navigate = useNavigate();
    const [booking_Type, setBooking_Type] = useState('Domestic');
    const [manualAWB, setManualAWB] = useState('');
    const [todaydate, setTodayDate] = useState(getTodayDateString());
    const [currentTime, setCurrentTime] = useState(new Date());
    const [product_Type, setProduct_Type] = useState('Parcel');
    const [billtype, setBilltype] = useState('Cash');
    const [billcustomerlist, setBillcustomerlist] = useState([]);
    const [customerlist, setCustomerlist] = useState([]);
    const [selectedcustomer, setSelectedcustomer] = useState([]);
    const [billpayid, setBillpayid] = useState('0');
    const [senderautofilltype, setSenderautofilltype] = useState('phone');
    const [senderautofilldata, setSenderautofilldata] = useState('');
    const [senderautofilllist, setSenderautofilllist] = useState('');
    const [sendercompanyname, setSendercompanyname] = useState('');
    const [sendercustomername, setSendercustomername] = useState('');
    const [sendermobilenumber, setSendermobilenumber] = useState('');
    const [senderemail, setSenderemail] = useState('');
    const [senderAddress, setSenderAddress] = useState('');
    const [senderPostalcode, setSenderPostalcode] = useState('');
    const [sendercountryname, setSendercountryname] = useState('');
    const [sendercountryid, setSendercountryid] = useState('');
    const [sendercityName, setSendercityName] = useState('');
    const [sendercityId, setSendercityId] = useState('');
    const [sendersaveaddress, setSendersaveaddress] = useState('');
    const [receiverautofilltype, setReceiverautofilltype] = useState('phone');
    const [receiverautofilldata, setReceiverautofilldata] = useState('');
    const [receiverautofilllist, setReceiverautofilllist] = useState('');
    const [receivercompanyname, setReceivercompanyname] = useState('');
    const [receivercustomername, setReceivercustomername] = useState('');
    const [receivermobilenumber, setReceivermobilenumber] = useState('');
    const [receiveremail, setReceiveremail] = useState('');
    const [receiverAddress, setReceiverAddress] = useState('');
    const [receiverPostalcode, setReceiverPostalcode] = useState('');
    const [receivercountryname, setReceivercountryname] = useState('');
    const [receivercountryid, setReceivercountryid] = useState('');
    const [receivercityName, setReceivercityName] = useState('');
    const [receivercityId, setReceivercityId] = useState('');
    const {headers1,headers2,logout}=AuthUser();
    const [paymenttype, setPaymenttype] = useState('Prepaid');
    const [codprice,setCodprice] = useState('0');
    const [insurance, setInsurance] = useState('No');
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const [showSelectBox, setShowSelectBox] = useState(false);
    const [countrylist, setCountrylist] = useState([]);
    const [defaultCountry, setDefaultCountry] = useState('');
    const [senderdefaultCountryid, setSenderDefaultCountryid] = useState('');
    const [receiverdefaultCountryid, setReceiverdefaultCountryid] = useState('');
    const [showReceiverSelectBox, setShowReceiverSelectBox] = useState(false);
    const [showCustomerSelectBox, setShowCustomerSelectBox] = useState(false);
    const [packages, setPackages] = useState([{ProductName:"",ProductPrice:"",ProductQTY:""}]); //,Height:"",Width:"",Length:"",Weight:""
    const [servicelist, setServicelist] = useState([]);
    const [selectedservicelist, setSelectedservicelist] = useState('');
    const [setloader, setSetloader] = useState(false);
    const [userdata, setUserdata] = useState('');
    const [citylist, setCitylist] = useState([]);
    const [pincodeSenderCity, setPincodeSenderCity] = useState([]);
    const [pincodeReceiverCity, setPincodeReceiverCity] = useState([]);
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [length, setLength] = useState('');
    const [width, setWidth] = useState('');


    useEffect(()=>{
        GetCountrylist();
        GetdefaultCountry();
        GetdefaultCountryid();
        const items = JSON.parse(localStorage.getItem('user'));
        if (items) {
         setUserdata(items);
        }
        const timerId = setInterval(() => {
            setCurrentTime(new Date());
        }, 10000);
        return () => clearInterval(timerId);
        
    },[]);

    const filterBy = (option, state)=> {
        if (state.selected.length) {
          return true;
        }
        return option.label.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
      }

    const handleTimeChange = (e) => {
        const [hours, minutes] = e.target.value.split(':');
        const newTime = new Date(currentTime);
        newTime.setHours(hours, minutes);
        setCurrentTime(newTime);
    };

    const addInput = () => {
            setPackages([...packages,{ProductName:"",ProductPrice:"",ProductQTY:""}]); //,Height:"",Width:"",Length:"",Weight:""

      };
      const handleDelete=(i)=>{
        const deleteVal = [...packages]
        deleteVal.splice(i,1)
        setPackages(deleteVal);
    }

    const handlechange=(e,i)=>{
        setServicelist([]);
        const {name,value} = e.target
        const onchangeVal = [...packages]
        onchangeVal[i][name]=value
        setPackages(onchangeVal);
    }

    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    
    const GetdefaultCountryid=()=>
    {
        axios({
            method: 'post',
            url: Global.baseUrl+'SiteconfigData',
            data: {
                fliedname: "default_country"
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setSenderDefaultCountryid(data);
                setReceiverdefaultCountryid(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const GetdefaultCountry=()=>
    {
        axios({
            method: 'post',
            url: Global.baseUrl+'defaultcountryname',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDefaultCountry(data);
                setSendercountryname(data);
                setReceivercountryname(data);
                GetCityList(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }

    const handelbilltypecustomer=(e)=>{
        setBilltype(e);
        GetBilltocustomerlist(e);
    }

    const handelbillbyid=(e)=>{
        const id = e[0].value;
        const name = e[0].label;
        setSelectedcustomer(name)
        setBillpayid(id);
    }
    

    const GetBilltocustomerlist=(val)=>{
        if(val !='Cash')
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'Customerlist',
                headers:headers1,
                data: {
                    cust_type: val
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    setBillcustomerlist(response.data.value);
                }
                else
                {
                    setBillcustomerlist([]);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
        }
    }

    // Function to get today's date in the format "YYYY-MM-DD"
    function getTodayDateString() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      const handlesenderdata=(id)=>{
        setSenderautofilldata(id);
        setShowSelectBox(false);
        axios({
            method: 'post',
            url: Global.baseUrl+'Customerdatabyuniqueid',
            headers:headers1,
            data: {
                uniqueid: id
            }
        })
        .then(function (response) {
            if(response.data.status == 200)
            {
                const data = response.data.value;
                setSendercompanyname(data.company);
                setSendercustomername(data.name);
                setSendermobilenumber(data.phone);
                setSenderemail(data.email);
                setSenderAddress(data.address);
                setSenderPostalcode(data.pincode);
                setSendercountryname(data.countryname);
                setSendercountryid(data.country);
                setSendercityName(data.cityname);
                setSendercityId(data.city);
                setPincodeSenderCity([{value: data.city, label:data.cityname}]);

            }
            else
            {
                setSendercustomername('');
                setSendermobilenumber('');
                setSenderemail('');
                setSenderAddress('');
                setSenderPostalcode('');
                setSendercountryname('');
                setSendercountryid('');
                setSendercityName('');
                setSendercityId('');
                setPincodeSenderCity([]);
                
            }
        })
        .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
        });
      }

      const handleReceiverdata=(id)=>{
        setReceiverautofilldata(id);
        setShowReceiverSelectBox(false);
        axios({
            method: 'post',
            url: Global.baseUrl+'Customerdatabyuniqueid',
            headers:headers1,
            data: {
                uniqueid: id
            }
        })
        .then(function (response) {
            if(response.data.status == 200)
            {
                const data = response.data.value;
                setReceivercompanyname(data.company);
                setReceivercustomername(data.name);
                setReceivermobilenumber(data.phone);
                setReceiveremail(data.email);
                setReceiverAddress(data.address);
                setReceiverPostalcode(data.pincode);
                setReceivercountryname(data.countryname);
                setReceivercountryid(data.country);
                setReceivercityName(data.cityname);
                setReceivercityId(data.city);
                setPincodeReceiverCity([{value: data.city, label:data.cityname}]);
                

            }
            else
            {
                setReceivercustomername('');
                setReceivermobilenumber('');
                setReceiveremail('');
                setReceiverAddress('');
                setReceiverPostalcode('');
                setReceivercountryname('');
                setReceivercountryid('');
                setReceivercityName('');
                setReceivercityId('');
                setPincodeReceiverCity([]);
                
            }
        })
        .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
        });
      }

      const handlesendersearchdata=(val)=>{
        setSenderautofilldata(val);
        if(val.length >2 && senderautofilltype !='')
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'Customerdatasearch',
                headers:headers1,
                data: {
                    senderautofilltype: senderautofilltype,
                    senderautofilldata: val
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    setSenderautofilllist(response.data.value);
                    setShowSelectBox(true);

                }
                else
                {
                    setSenderautofilllist([]);
                    setShowSelectBox(false);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
        }
        else
        {
            setShowSelectBox(false);
        }
      }

      const handlereceiversearchdata=(val)=>{
        setReceiverautofilldata(val);
        if(val.length >2 && receiverautofilltype !='')
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'Customerdatasearch',
                headers:headers1,
                data: {
                    senderautofilltype: receiverautofilltype,
                    senderautofilldata: val
                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    setReceiverautofilllist(response.data.value);
                    setShowReceiverSelectBox(true);

                }
                else
                {
                    setReceiverautofilllist([]);
                    setShowReceiverSelectBox(false);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
        }
        else
        {
            setShowReceiverSelectBox(false);
        }
      }

      const handelsendercountry=(e)=>{
        setSendercountryname(e.target.options[e.target.selectedIndex].text);
        GetCityList(e.target.options[e.target.selectedIndex].text);
        setSendercityName('');
        setSendercityId('');
        setSendercountryid(e.target.value);
        setServicelist([]);
        setPincodeSenderCity([]);
      }

      const handelreceivercountry=(e)=>{
        setReceivercountryname(e.target.options[e.target.selectedIndex].text);
        GetCityList(e.target.options[e.target.selectedIndex].text);
        setReceivercityName('');
        setReceivercityId('');
        setReceivercountryid(e.target.value);
        setServicelist([]);
        setPincodeReceiverCity([]);
      }

      const handelsenderautoselectcity = (selectedOptions) => {
        setPincodeSenderCity(selectedOptions);
        if (selectedOptions.length > 0) {
          const selectedOption = selectedOptions[0];
          setSendercityName(selectedOption.label);
          setSendercityId(selectedOption.value);
        } else {
            setSendercityName('');
          setSendercityId('');
        }
        setServicelist([]);
      };

      const handelReceiverautoselectcity=(e)=>{
        setPincodeReceiverCity(e)
        if (e.length > 0) {
            const id = e[0].value;
            const name = e[0].label;
            setReceivercityName(name);
            setReceivercityId(id);
        }
        else
        {
            setReceivercityName('');
            setReceivercityId('');
        }
        setServicelist([]);
      }

      const handelservice=()=>{
        if(sendercustomername =="")
        {
            toast.warn('Please Enter Sender Customer Name');
        }
        else if(sendermobilenumber =="")
        {
            toast.warn('Please Enter Sender Customer Mobile Number');
        }
        else if(senderAddress =="")
        {
            toast.warn('Please Enter Sender Customer Address');
        }
        else if(sendercountryname =="")
        {
            toast.warn('Please Select Sender Country');
        }
        else if(sendercityName =="")
        {
            toast.warn('Please Select Sender City');
        }
        else if(receivercustomername =="")
        {
            toast.warn('Please Enter Receiver Customer Name');
        }
        else if(receivermobilenumber =="")
        {
            toast.warn('Please Enter Receiver Customer Mobile Number');
        }
        else if(receiverAddress =="")
        {
            toast.warn('Please Enter Receiver Customer Address');
        }
        else if(receivercountryname =="")
        {
            toast.warn('Please Select Receiver Country');
        }
        else if(receivercityName =="")
        {
            toast.warn('Please Select Receiver city');
        }
        else if(packages[0].ProductName =="")
        {
            toast.warn('Please Enter Product Name');
        }
        else if(packages[0].ProductPrice =="")
        {
            toast.warn('Please Enter Product Price');
        }
        else if(packages[0].ProductQTY =="")
        {
            toast.warn('Please Enter Product Qty');
        }
        else if(height =="")
        {
            toast.warn('Please Enter Product Height');
        }
        else if(width =="")
        {
            toast.warn('Please Enter Product Width');
        }
        else if(length =="")
        {
            toast.warn('Please Enter Product Length');
        }
        else if(weight =="")
        {
            toast.warn('Please Enter Product Weight');
        }
        else
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'showservice',
                headers:headers1,
                data: {
                    sendercountryid: sendercountryid,
                    sendercityId : sendercityId,
                    receivercountryid: receivercountryid,
                    receivercityId: receivercityId,
                    products: packages,
                    height: height,
                    width: width,
                    length: length,
                    weight: weight,
                    booking_Type: booking_Type,
                    product_Type: product_Type,
                    cust_id:billpayid,
                    insurance:insurance,
                    paymenttype:paymenttype,
                    codprice:codprice,
                    type:userdata.usertype,
                    agent_id:0,

                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    setServicelist(response.data.value);
                }
                else
                {
                    toast.warn(response.data.message);
                    setServicelist([]);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
        }
      }

      const handelsenderpostalcode=(zipcode)=>{
        setServicelist([]);
        axios({
            method: 'post',
            url: Global.baseUrl+'countrydatabypincode',
            headers:headers1,
            data: {
                Postalcode: zipcode,
            }
        })
        .then(function (response) {
            if(response.data.status == 200)
            {
                setSenderDefaultCountryid('');
                setSendercountryid(response.data.value.countryid);
                setSendercityName(response.data.value.city);
                setSendercityId(response.data.value.id);
                setPincodeSenderCity([{value: response.data.value.id, label: response.data.value.city}]);
            }
            else
            {
                setSendercountryid('');
                setSendercityName('');
                setSendercityId('');
                setPincodeSenderCity([]);
            }
        })
        .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
        });
        }

        const handelreceiverpostalcode=(zipcode)=>{
            setServicelist([]);
        axios({
        method: 'post',
        url: Global.baseUrl+'countrydatabypincode',
        headers:headers1,
        data: {
            Postalcode: zipcode,
        }
        })
        .then(function (response) {
        if(response.data.status == 200)
        {
            setReceiverdefaultCountryid('');
            setReceivercountryid(response.data.value.countryid);
            setReceivercityName(response.data.value.city);
            setReceivercityId(response.data.value.id);
            setPincodeReceiverCity([{value: response.data.value.id, label: response.data.value.city}]);
            
        }
        else
        {
            setReceivercountryid('');
            setReceivercityName('');
            setReceivercityId('');
            setPincodeReceiverCity([]);
        }
        })
        .catch(function (error) {
        if(error.response.status==401){
            toast.error(error.response.statusText);
            logout();
            navigate('/');
            window.location.reload();
        }
        // console.log(error);
        });
        }
      const handelactiveservice=(index)=>{
             const newList= servicelist[index];
             setSelectedservicelist(newList);
      }
      const createshipment=()=>{
        if(sendercustomername =="")
        {
            toast.warn('Please Enter Sender Customer Name');
        }
        else if(sendermobilenumber =="")
        {
            toast.warn('Please Enter Sender Customer Mobile Number');
        }
        else if(senderAddress =="")
        {
            toast.warn('Please Enter Sender Customer Address');
        }
        else if(sendercountryname =="")
        {
            toast.warn('Please Select Sender Country');
        }
        else if(sendercityName =="")
        {
            toast.warn('Please Select Sender City');
        }
        else if(receivercustomername =="")
        {
            toast.warn('Please Enter Receiver Customer Name');
        }
        else if(receivermobilenumber =="")
        {
            toast.warn('Please Enter Receiver Customer Mobile Number');
        }
        else if(receiverAddress =="")
        {
            toast.warn('Please Enter Receiver Customer Address');
        }
        else if(receivercountryname =="")
        {
            toast.warn('Please Select Receiver Country');
        }
        else if(receivercityName =="")
        {
            toast.warn('Please Select Receiver city');
        }
        else if(packages[0].ProductName =="")
        {
            toast.warn('Please Enter Product Name');
        }
        else if(packages[0].ProductPrice =="")
        {
            toast.warn('Please Enter Product Price');
        }
        else if(packages[0].ProductQTY =="")
        {
            toast.warn('Please Enter Product Qty');
        }
        else if(height =="")
        {
            toast.warn('Please Enter Product Height');
        }
        else if(width =="")
        {
            toast.warn('Please Enter Product Width');
        }
        else if(length =="")
        {
            toast.warn('Please Enter Product Length');
        }
        else if(weight =="")
        {
            toast.warn('Please Enter Product Weight');
        }
        else if(selectedservicelist =="")
        {
            toast.warn("Please Select Service");
        }
        else
        {
            setSetloader(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'CreateShipment',
                headers:headers1,
                data: {
                    booking_Type: booking_Type,
                    manualAWB:manualAWB,
                    todaydate:todaydate,
                    currentTime:formattedTime,
                    product_Type: product_Type,
                    billtype:billtype,
                    sendercompanyname:sendercompanyname,
                    sendercustomername:sendercustomername,
                    sendermobilenumber:sendermobilenumber,
                    senderemail:senderemail,
                    senderAddress:senderAddress,
                    senderPostalcode:senderPostalcode,
                    sendercityId : sendercityId,
                    sendercountryid:sendercountryid,
                    receivercompanyname:receivercompanyname,
                    receivercustomername:receivercustomername,
                    receivermobilenumber:receivermobilenumber,
                    receiveremail:receiveremail,
                    receiverAddress:receiverAddress,
                    receiverPostalcode:receiverPostalcode,
                    receivercityId: receivercityId,
                    receivercountryid:receivercountryid,
                    productsdetails: packages,
                    height: height,
                    width: width,
                    length: length,
                    weight: weight,
                    paymenttype:paymenttype,
                    codprice:codprice,
                    insurance:insurance,
                    servicedetails:selectedservicelist,
                    cust_id:billpayid,
                    paymenttype:paymenttype,
                    type:userdata.usertype,
                    agent_id:0,
                    user_id:userdata.id,
                    hub_id:0

                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setSetloader(false);
                    setTimeout(() => {
                        navigate('/Shipmentlist/All');
                      }, 1000);
                }
                else
                {
                    toast.warn(response.data.message);
                    setSetloader(false);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
        }
      }

    return (
        <>
            <LeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer/>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                        <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">All Shipments</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" type="button" onClick={()=>navigate(-1)}>
                                    <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Create Shipment</h2>
                            </div>
                        </div>
                    </div>

                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>

                <section>
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <section className="mb-4">
                                <h5>Shipment Details</h5>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                        <div className="col-12 col-md-12 mb-3">
                                            <label className="form-label">Shipment Booking Type:</label>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" id="Domestic" checked={booking_Type == 'Domestic'} onChange={()=>setBooking_Type('Domestic')}/>
                                                        <label className="form-check-label" for="Domestic">Domestic</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" id="International" checked={booking_Type == 'International'} onChange={()=>setBooking_Type('International')}/>
                                                        <label className="form-check-label" for="International">International</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label className="form-label">Shipment AWB Number <small>(Leave blank for auto generate)</small>:</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-package"></i></span>
                                                    <input type="text" className="form-control" placeholder="Enter Manual Shipment AWB Number" onChange={(e)=>setManualAWB(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 mb-3">
                                                <label className="form-label">Date:</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-calendar"></i></span>
                                                    <input type="date" className="form-control" placeholder="Date" value={todaydate} onChange={(e)=>setTodayDate(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 mb-3">
                                                <label className="form-label">Time:</label>
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="bx bx-time"></i></span>
                                                    <input type="time" className="form-control" placeholder="Time" value={formattedTime} onChange={handleTimeChange} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <label className="form-label">Shipment Product Type:</label>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" id="Parcel" checked={product_Type == 'Parcel'} onChange={()=>setProduct_Type('Parcel')}/>
                                                        <label className="form-check-label" for="Parcel">Parcel</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" id="Document" checked={product_Type == 'Document'} onChange={()=>setProduct_Type('Document')}/>
                                                        <label className="form-check-label" for="Document">Document</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <label className="form-label">Billing Type:</label>
                                                <select className="form-select" onChange={(e)=>handelbilltypecustomer(e.target.value)}>
                                                    <option value='Cash'>Cash</option>
                                                    <option value='Contract'>Contract</option>
                                                    <option value='Credit'>Credit</option>
                                                    <option value='Wallet'>Wallet</option>
                                                </select>
                                            </div>
                                            {billtype !='Cash' &&
                                            <div className="col-12 col-md-4 mb-3">
                                                <label className="form-label">Select Customer:</label>
                                                <Typeahead
                                                    filterBy={filterBy}
                                                    id="toggle-example1"
                                                    options={billcustomerlist}
                                                    placeholder="Choose a customer..."
                                                    onChange={handelbillbyid}
                                                />
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="mb-4">
                                <div className="row">
                                    <div className="col-12 col-md">
                                        <h5>Add Senders Details</h5>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <small className="text-muted">Where is the order being sent from?</small>
                                                    <div className="text-end">
                                                        <small><a data-bs-toggle="collapse" data-bs-target="#autofill" className="text-primary">Use Auto Fill<i className="bx bx-chevron-down"></i></a></small>
                                                    </div>
                                                </div>

                                                <div className="row ">
                                                    <div className="col-12 col-md-12 mb-3 collapse" id="autofill">
                                                        <label className="form-label">Auto Fill details using:</label>
                                                        <div className="input-group select_account">
                                                            <select className="form-select" style={{"width": "20px !important"}} onChange={(e)=>setSenderautofilltype(e.target.value)}>
                                                                {/* <option value=''>--Please Select--</option> */}
                                                                <option value='phone'>Mobile</option>
                                                                <option value='uniqueid'>Account Number</option>
                                                                <option value='address'>Address UID</option>
                                                            </select>
                                                            <input type="text" className="form-control" placeholder="Enter details to fetch data" value={senderautofilldata} onChange={(e)=>handlesendersearchdata(e.target.value)} />
                                                            
                                                        </div>
                                                        {showSelectBox &&
                                                            <select className="form-select customtypehead" size="5" onChange={(e)=>handlesenderdata(e.target.value)}>
                                                                {senderautofilllist.map((al,i)=>{
                                                                return(
                                                                <option value={al.value} className="searchtypehead">{al.label} {al.value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            }
                                                    </div>
                                                    <div className="col-12 col-md-12 mb-3">
                                                        <label className="form-label">Company Name:</label>
                                                        <input type="text" className="form-control" value={sendercompanyname} placeholder="Enter Company Name" onChange={(e)=>setSendercompanyname(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-12 mb-3">
                                                        <label className="form-label">Full Customer Name <span className="text-danger">*</span> :</label>
                                                        <input type="text" className="form-control" value={sendercustomername} placeholder="Enter Customer Name" onChange={(e)=>setSendercustomername(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label">Mobile Number <span className="text-danger">*</span>:</label>
                                                        <input type="text" className="form-control" value={sendermobilenumber} placeholder="Enter Mobile Number" onChange={(e)=>setSendermobilenumber(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label">Email ID:</label>
                                                        <input type="text" className="form-control" value={senderemail} placeholder="Enter email address" onChange={(e)=>setSenderemail(e.target.value)}/>
                                                    </div>
                                                    <div className="col-12 col-md-12 mb-3">
                                                        <label className="form-label">Complete Address <span className="text-danger">*</span>:</label>
                                                        <input type="text" className="form-control" value={senderAddress} placeholder="Enter full address" onChange={(e)=>setSenderAddress(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-12 mb-3">
                                                        <label className="form-label">Pin/Postal/Zip code:</label>
                                                        <input type="text" className="form-control" value={senderPostalcode} placeholder="Enter pincode" onChange={(e)=>{setSenderPostalcode(e.target.value);handelsenderpostalcode(e.target.value)}}/>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label">Country <span className="text-danger">*</span>:</label>
                                                        <select className="form-select" onChange={handelsendercountry}>
                                                            <option>--Please Select--</option>
                                                            {countrylist.map((cl)=>{
                                                                return(
                                                                    <option value={cl.id}  key={cl.id} selected={senderdefaultCountryid === cl.id || sendercountryid === cl.id}>{cl.country}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label">City <span className="text-danger">*</span>:</label>
                                                        <Typeahead
                                                            filterBy={filterBy}
                                                            id="toggle-example"
                                                            options={citylist}
                                                            placeholder="Choose a city..."
                                                            onChange={handelsenderautoselectcity}
                                                            selected={pincodeSenderCity}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md">
                                        <h5>Add Receiver Details</h5>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <small className="text-muted">Where is the order being sent from?</small>
                                                    <div className="text-end">
                                                        <small><a data-bs-toggle="collapse" data-bs-target="#recautofill" className="text-primary">Use Auto Fill<i className="bx bx-chevron-down"></i></a></small>
                                                    </div>
                                                </div>

                                                <div className="row ">
                                                    <div className="col-12 col-md-12 mb-3 collapse" id="recautofill">
                                                        <label className="form-label">Auto Fill details using:</label>
                                                        <div className="input-group select_account">
                                                            <select className="form-select" style={{"width": "20px !important"}} onChange={(e)=>setReceiverautofilltype(e.target.value)}>
                                                                {/* <option value=''>--Please Select--</option> */}
                                                                <option value='phone'>Mobile</option>
                                                                <option value='uniqueid'>Account Number</option>
                                                                <option value='address'>Address UID</option>
                                                            </select>
                                                            <input type="text" className="form-control" placeholder="Enter details to fetch data" value={receiverautofilldata} onChange={(e)=>handlereceiversearchdata(e.target.value)} />
                                                            
                                                        </div>
                                                        {showReceiverSelectBox &&
                                                            <select className="form-select customtypehead" size="5" onChange={(e)=>handleReceiverdata(e.target.value)}>
                                                                {receiverautofilllist.map((al,i)=>{
                                                                return(
                                                                <option value={al.value} className="searchtypehead">{al.label} {al.value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            }
                                                    </div>
                                                    <div className="col-12 col-md-12 mb-3">
                                                        <label className="form-label">Company Name:</label>
                                                        <input type="text" className="form-control" value={receivercompanyname} placeholder="Enter Company Name" onChange={(e)=>setReceivercompanyname(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-12 mb-3">
                                                        <label className="form-label">Full Customer Name <span className="text-danger">*</span> :</label>
                                                        <input type="text" className="form-control" value={receivercustomername} placeholder="Enter Customer Name" onChange={(e)=>setReceivercustomername(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label">Mobile Number <span className="text-danger">*</span>:</label>
                                                        <input type="text" className="form-control" value={receivermobilenumber} placeholder="Enter Mobile Number" onChange={(e)=>setReceivermobilenumber(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label">Email ID:</label>
                                                        <input type="text" className="form-control" value={receiveremail} placeholder="Enter email address" onChange={(e)=>setReceiveremail(e.target.value)}/>
                                                    </div>
                                                    <div className="col-12 col-md-12 mb-3">
                                                        <label className="form-label">Complete Address <span className="text-danger">*</span>:</label>
                                                        <input type="text" className="form-control" value={receiverAddress} placeholder="Enter full address" onChange={(e)=>setReceiverAddress(e.target.value)} />
                                                    </div>
                                                    <div className="col-12 col-md-12 mb-3">
                                                        <label className="form-label">Pin/Postal/Zip code:</label>
                                                        <input type="text" className="form-control" value={receiverPostalcode} placeholder="Enter pincode" onChange={(e)=>{setReceiverPostalcode(e.target.value);handelreceiverpostalcode(e.target.value)}}/>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label">Country <span className="text-danger">*</span>:</label>
                                                        <select className="form-select" onChange={handelreceivercountry}>
                                                            <option>--Please Select--</option>
                                                            {countrylist.map((cl,i)=>{
                                                                return(
                                                                    <option value={cl.id} selected={receiverdefaultCountryid == cl.id || receivercountryid == cl.id} key={i}>{cl.country}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label">City <span className="text-danger">*</span>:</label>
                                                        <Typeahead
                                                            filterBy={filterBy}
                                                            id="toggle-example"
                                                            options={citylist}
                                                            placeholder="Choose a city..."
                                                            onChange={handelReceiverautoselectcity}
                                                            selected={pincodeReceiverCity}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="mb-4">
                                <h5>Product Details</h5>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                {/*<button type="button" className="btn btn-primary btn-sm btn-block " onClick={addInput}>Click to Add More</button>
                                                 <label className="form-label">No of Packages <span className="text-danger">*</span>:</label> */}
                                                {/* <select className="form-select" onChange={(e)=>addInput(e.target.value)}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select> */}
                                            </div>
                                            <div className="col-12 col-md-4"></div>
                                        </div>
                                        {packages.map((val, i) => (
                                            <div className="card bg-gray-100" key={i}>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12 col-md-1">
                                                            <div className="ship_number">
                                                                {i+1}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-11">
                                                            <div className="row">
                                                                <div className="col-12 col-md-5 mb-3">
                                                                    <label className="form-label">Product Name <span className="text-danger">*</span>:</label>
                                                                    <input type="text" className="form-control" name="ProductName" placeholder="Enter Product name" value={val.ProductName} onChange={(e)=>handlechange(e,i)}/>
                                                                </div>
                                                                <div className="col-12 col-md-3 mb-3">
                                                                    <label className="form-label">Product Price <span className="text-danger">*</span>:</label>
                                                                    <input type="number" className="form-control" name="ProductPrice" placeholder="Enter price" value={val.ProductPrice} onChange={(e)=>handlechange(e,i)}/>
                                                                </div>
                                                                <div className="col-12 col-md-2 mb-3">
                                                                    <label className="form-label">Product QTY <span className="text-danger">*</span>:</label>
                                                                    <input type="number" className="form-control" name="ProductQTY" placeholder="Enter qty" value={val.ProductQTY} onChange={(e)=>handlechange(e,i)}/>
                                                                </div>
                                                                <div className="col-12 col-md-2">
                                                                    <div className="mt-4">
                                                                        {i ===0 ?
                                                                        <button className="btn btn-primary" onClick={addInput}><i className="bx bx-plus"></i></button>
                                                                        :
                                                                        <button className="btn btn-primary" onClick={()=>handleDelete(i)}><svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24"><path fill="currentColor" d="M5 11v2h14v-2z"/></svg></button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="card bg-gray-100">
                                            <div className="card-body">
                                                <div className="row">
                                                    {/* <div className="col-12 col-md-1">
                                                        <div className="ship_number">
                                                            
                                                        </div>
                                                    </div> */}
                                                    <div className="col-12 col-md-11">
                                                        <div className="row">
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-gray-200">Height <span className="text-danger">*</span></span>
                                                                    <input type="number" className="form-control" name="Height" placeholder="0" onChange={(e)=>setHeight(e.target.value)} />
                                                                    <span className="input-group-text">CM</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-gray-200">Width <span className="text-danger">*</span></span>
                                                                    <input type="number" className="form-control" name="Width" placeholder="0" onChange={(e)=>setWidth(e.target.value)} />
                                                                    <span className="input-group-text">CM</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-gray-200">Length <span className="text-danger">*</span></span>
                                                                    <input type="number" className="form-control" name="Length" placeholder="0" onChange={(e)=>setLength(e.target.value)} />
                                                                    <span className="input-group-text">CM</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-gray-200">Weight <span className="text-danger">*</span></span>
                                                                    <input type="number" className="form-control" name="Weight" placeholder="0" onChange={(e)=>setWeight(e.target.value)} />
                                                                    <span className="input-group-text">KG</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end mt-3">
                                            {/* <h6>Total Order Value: $309.00</h6> */}
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="mb-4">
                                <h5>Order Details</h5>
                                <div className="card">
                                    <div className="card-body">
                                        <h6>Payment Details</h6>
                                        <small>Select mode of payment that your buyer has chosen for the order</small>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" id="inlineRadiod20" checked={paymenttype == 'Prepaid'} onChange={()=>setPaymenttype('Prepaid')} />
                                                    <label className="form-check-label" for="inlineRadiod20">Prepaid <a href="#" title="Order Payment already received from the buyer"><i className="bx bx-help-circle"></i></a></label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" id="inlineRadiod21" checked={paymenttype == 'COD'} onChange={()=>setPaymenttype('COD')} />
                                                    <label className="form-check-label" for="inlineRadiod21">Cash On Delivery <a href="#" title="Order Payment to be collected from the buyer on shipment delivery"><i className="bx bx-help-circle"></i></a></label>
                                                </div>
                                            </div>
                                            {paymenttype =='COD' &&
                                            <div className="col-12 col-md-4">
                                                <input type="text" className="form-control" placeholder="Enter COD Amount" onChange={(e)=>setCodprice(e.target.value)} />
                                            </div>
                                            }
                                        </div>
                                        <hr />

                                        <h6>Insurance</h6>
                                        <small>Select the insurance mode for the shipment</small>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" id="or" value="option2" checked={insurance == 'No'} onChange={()=>setInsurance('No')} />
                                                    <label className="form-check-label" for="or">OR <a href="#" title="Owner Risk"><i className="bx bx-help-circle"></i></a></label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" id="cr" checked={insurance == 'Yes'} onChange={()=>setInsurance('Yes')} />
                                                    <label className="form-check-label" for="cr">CR <a href="#" title="Carrier Risk"><i className="bx bx-help-circle"></i></a></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {servicelist != undefined && servicelist.length > 0 ?
                                <section className="sele_service mb-4">
                                    <h5>Choose shipping service</h5>
                                    {servicelist != undefined && servicelist.length > 0 ?
                                    <>
                                    {servicelist.map((ser,i)=>{
                                        return(
                                        <div className="list-group mb-3" key={i}>
                                            <button type="button" className={`list-group-item list-group-item-action ${selectedservicelist.unique_id == ser.unique_id ?'active':''}`} onClick={()=>handelactiveservice(i)}>
                                                <div className="text-center">
                                                    <h2><small>$</small>{ser.totalprice}</h2>
                                                    <h6>{ser.services_name}</h6>
                                                </div>
                                            </button>
                                        </div>
                                        );
                                        })}
                                        </>
                                    :
                                        <div className="list-group">
                                            <a href="#" className="list-group-item list-group-item-action bg-danger">
                                                <div className="text-center text-white">
                                                    <h2 className=""><i className="bx bx-error"></i></h2>
                                                    <h6>No service available for the selected locations</h6>
                                                </div>
                                            </a>
                                        </div>
                                    }
                                </section>
                            :
                                <div className="text-center">
                                    <button type="button" className="btn btn-primary btn-lg btn-block center" onClick={()=>handelservice()}>Show service</button>
                                </div>    
                            }
                        </div>
                        <div className="col-12 col-md sticky-bottom">
                            {sendercityName !='' &&
                            <section className="mb-4 location_box_shipment">
                                <h5>Shipment Details</h5>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="timeline">
                                            <div className="event">
                                                <div className="date"><span className="text-danger l_icon"><i className="bx bx-map"></i></span>{sendercityName} - {sendercountryname}</div>
                                                <div className="location">{sendermobilenumber}, {senderAddress}, {sendercityName}, {sendercountryname} - {senderPostalcode}</div>
                                            </div>
                                            <div className="event">
                                                <div className="date"><span className="text-success l_icon"><i className="bx bx-map"></i></span>{receivercityName}- {receivercountryname}</div>
                                                <div className="location">{receivermobilenumber}, {receiverAddress}, {receivercityName}, {receivercountryname} - {receiverPostalcode}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            }
                            <section className="mb-4">
                                <h5>Shipment Details</h5>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 mb-3">
                                                <label className="form-label"><small>Shipment Product Type</small>:</label>
                                                <p>{product_Type}</p>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <label className="form-label"><small>Order Mode</small>:</label>
                                                <p>{paymenttype}</p>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <label className="form-label"><small>Insurance Type</small>:</label>
                                                <p>{insurance}</p>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <label className="form-label"><small>Total Weight <a href="" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-content="Total physical weight of shipment"><i className="bx bx-help-circle"></i></a></small>:</label>
                                                <p>{packages[0].Weight} KG</p>
                                            </div>
                                            <div className="col-12 col-md-12 mb-3">
                                                <label className="form-label"><small>Volumetric Weight <a href="" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-content="Volumetric Weight is calculated by HxWxL/5000"><i className="bx bx-help-circle"></i></a> </small>:</label>
                                                <p>{servicelist != undefined && servicelist.length > 0 && servicelist[0].volumetricweight}KG</p>
                                            </div>
                                    <div className="col-12 col-md-12 mb-3">
                                        <div className="card border-0 bg-gray-100">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <h6>Total Chargeable Weight</h6>
                                                    <h6>{servicelist != undefined && servicelist.length > 0 && servicelist[0].volumetricweight} KG</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>
                {selectedservicelist !=undefined && selectedservicelist.totalprice &&
                <section className="cost_brake mb-4">
                    <h6 className="">Shipping Cost Breakup</h6>
                    <div className="card">
                        <div className="card-body">
                            <table className="table">
                                <tbody>
                                    {selectedservicelist !=undefined && selectedservicelist.service_charge > 0 &&
                                    <tr>
                                        <td>Freight Cost</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.service_charge}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.totalFuelsurchage > 0 &&
                                    <tr>
                                        <td>Fuel Surcharge</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.totalFuelsurchage}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.custom_clearence > 0 &&
                                    <tr>
                                        <td>Custom Clearance</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.custom_clearence}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.codCharge > 0 &&
                                    <tr>
                                        <td>COD</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.codCharge}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.insurance_chagre > 0 &&
                                    <tr>
                                        <td>Insurance</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.insurance_chagre}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.esscharge > 0 &&
                                    <tr>
                                        <td>ESS Surcharge</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.esscharge}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.elevated_risk_charge > 0 &&
                                    <tr>
                                        <td>Elevated Risk</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.elevated_risk_charge}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.restricted_destination > 0 &&
                                    <tr>
                                        <td>Restricted Destination</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.restricted_destination}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.remoterareadelivery > 0 &&
                                    <tr>
                                        <td>Remote Area Delivery</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.remoterareadelivery}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.service_tax_val > 0 &&
                                    <tr>
                                        <td>GST - {selectedservicelist !=undefined && selectedservicelist.service_tax}%</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.service_tax_val}</td>
                                    </tr>
                                    }
                                    {selectedservicelist !=undefined && selectedservicelist.vat_tax_val > 0 &&
                                    <tr>
                                        <td>VAT - {selectedservicelist !=undefined && selectedservicelist.vat_tax}%</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.vat_tax_val}</td>
                                    </tr>
                                    }
                                    <tr>
                                        <td>Total</td>
                                        <td>{selectedservicelist !=undefined && selectedservicelist.totalprice}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                }
                {selectedservicelist !='' && (
                setloader == false ? (
                <div>
                    <div className="d-grid">
                        <button type="button" className="btn btn-primary btn-lg btn-block " onClick={()=>createshipment()}>Create Shipment </button>
                    </div>
                </div>
                ):(
                <div>
                    <div className="d-grid">
                        <a href="" className="btn btn-primary btn-lg btn-block disabled"> <span className="spinner"></span></a>
                    </div>
                </div>
                )
           ) }
            </div>
        </div >
            </section >
        <Footer />
            </main >
        </>
    )
}
export default Createshipment;