import React, {useState, useEffect} from "react";
import Header from "./include/header";
import Footer from "./include/footer";
import LeftPanel from "./include/LeftPanel";
import Global from "../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import MainLoader from "./Loaders/MainLoader";


function CustomerEdit(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [Name, setName] = useState('');
    const [Company, setCompany] = useState('');
    const [Phone, setPhone] = useState('');
    const [Email, setEmail] = useState('');
    const [Zip, setZip] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [Hub, setHub] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPass, setConfirmPass] = useState('');
    const [Address, setAddress] = useState('');
    const [PanNo, setPanNo] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [oldprofileImage, setOldprofileImage] = useState('');
    const [Countrylist, setCountrylist] = useState([]);
    const [Citylist, setCitylist] = useState([]);
    const [Hublist, setHublist] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        GetCustomerDetail();
        GetCountrylist();
    },[]);
    const items = JSON.parse(localStorage.getItem('user'));
    const GetCustomerDetail=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetFrontCustomerDetailForEdit',
            headers:headers2,
            data: {
                UniqueId:items.uniqueid,
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setName(data.name);
                setCompany(data.company);
                setPhone(data.phone);
                setEmail(data.email);
                setZip(data.pincode);
                setCountry(data.country_name);
                setCity(data.city);
                setHub(data.hub_id);
                setAddress(data.address);
                setPanNo(data.pan_num);
                setOldprofileImage(data.customer_image);
                handleCountryChange(data.country_name);
                handleCityChange(data.city);
                setLoading(false);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            setLoading(false);
          });
    }
    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const handleCountryChange = (val) => {
        setCountry(val);
        GetCityList(val);
    };
    const handleCityChange = (val) => {
        setCity(val);
        GetHubList(val);
    };
    const GetHubList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Hublist',
            data: {
                cityid: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setHublist(data);
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setLoading(false);
          });
    }
    const handleSubmit=()=>{
       if(Name==""){
            toast.warning('Customer Name is Required');
        }
        else if(Company==""){
            toast.warning('Company Name is Required');
        }
        else if(Phone==""){
            toast.warning('Customer Phone NO. is Required');
        }
        else if(Email==""){
            toast.warning('Customer Email is Required');
        }
        else if(Zip==""){
            toast.warning('Postal Code is Required');
        }
        else if(country==""){
            toast.warning('Please Select Country');
        }
        else if(city==""){
            toast.warning('Please Select City');
        }
        else if(Hub==""){
            toast.warning('Please Select Hub');
        }
        else if(Address==""){
            toast.warning('Address is Required');
        }
        else{
            setSubloading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'FrontCustomerEdit',
                headers:headers2,
                data: {
                    UniqueId:items.uniqueid,
                    Name:Name,
                    Company:Company,
                    Phone:Phone,
                    Email:Email,
                    Zip:Zip,
                    Country:country,
                    City:city,
                    Hub:Hub,
                    Address:Address,
                    PanNo:PanNo,
                    oldprofileImage:oldprofileImage,
                    profileImage:profileImage
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    setSubloading(false);
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/UpdateProfile');
                      }, 1000);
                }else{
                    setSubloading(false);
                    toast.warning(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                setSubloading(false);
              });
        }
    }

    const handelpostalcode=(zipcode)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'countrydatabypincode',
            headers:headers1,
            data: {
                Postalcode: zipcode,
            }
        })
        .then(function (response) {
            if(response.data.status == 200)
            {
                GetCityList(response.data.value.country)
                setCountry(response.data.value.country);
                setCity(response.data.value.id);
            }
            else
            {
                GetCityList('');
                setCountry('');
                setCity('');
            }
        })
        .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
        });
        }

    return(
        <>
            <LeftPanel/>
            <main className='home-section'>
                <Header/>
                <ToastContainer/>
                {Loading==true?<MainLoader/>
                :
                <section className="left_sub_menu">
                    <div className="row">
                        <div className="col-12 col-md">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                <div className="d-block mb-4 mb-md-0">
                                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                            <li className="breadcrumb-item">
                                                <a href="/Admin-Dash">
                                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                                </a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">Update Profile</li>
                                        </ol>
                                    </nav>
                                    <div className="d-flex justify-content-between">
                                        <div className="">
                                            <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                            <i className="bx bx-arrow-back "></i>
                                            </button>
                                        </div>
                                        <div className="ps-2 ">
                                            <h2 className="h4">Update Profile</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                <h6>Update Profile</h6>
                                    <div className="row">
                                        <div className="col-12 mb-3 mt-4">
                                            <strong>Customer Basic Details</strong>
                                            <div className="row">
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Name: <span className="text-danger">*</span></label>
                                                    <input type="text" disabled className="form-control" value={Name} onChange={(e)=>setName(e.target.value)} placeholder="Please enter customer name" />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Company Name: <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" value={Company} onChange={(e)=>setCompany(e.target.value)} placeholder="Please enter customer company name" />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Phone: <span className="text-danger">*</span></label>
                                                    <input type="number" className="form-control" value={Phone} onChange={(e)=>setPhone(e.target.value)} placeholder="Please enter customer phone no." />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Email: <span className="text-danger">*</span></label>
                                                    <input type="email" disabled className="form-control" value={Email} onChange={(e)=>setEmail(e.target.value)} placeholder="Please enter customer email" />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Postal Code: <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" value={Zip} onChange={(e)=>{setZip(e.target.value);handelpostalcode(e.target.value)}} placeholder="Please enter customer postal code" />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <div><label for="email" className="form-label">Country: <span className="text-danger">*</span></label></div>
                                                    <select className="form-select" onChange={(e)=>handleCountryChange(e.target.value)}>
                                                        <option value="">-- Please Select --</option>
                                                        {Countrylist.map((list)=>{
                                                            return (
                                                                <option selected={country==list.country?true:false} value={list.country}>{list.country}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <div><label for="email" className="form-label">City: <span className="text-danger">*</span></label></div>
                                                    <select className="form-select" onChange={(e)=>handleCityChange(e.target.value)}>
                                                        <option value="">-- Please Select --</option>
                                                        {Citylist.map((list)=>{
                                                            return (
                                                                <option selected={list.value==city?true:false} value={list.value}>{list.label}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                {/* <div className="col-4 mb-3">
                                                    <div><label for="email" className="form-label">Hub: <span className="text-danger">*</span></label></div>
                                                    <select className="form-select" onChange={(e)=>setHub(e.target.value)}>
                                                        <option value="">-- Please Select --</option>
                                                        {Hublist.map((list)=>{
                                                            return (
                                                                <option selected={list.value==Hub?true:false} value={list.value}>{list.label}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div> */}
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Image: <span className="text-danger"></span></label>
                                                    <input type="file" className="form-control" onChange={(e)=>setProfileImage(e.target.files[0])} placeholder="Please Select File" />
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <label for="" className="form-label">Address: <span className="text-danger">*</span></label>
                                                    <textarea value={Address} className="form-control" rows={5} onChange={(e)=>setAddress(e.target.value)} placeholder="Please enter customer address" ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {Subloading==false?
                                    <div className="d-grid col-md-2 mt-2">
                                        <button className="btn btn-primary" onClick={()=>handleSubmit()}>Submit</button>
                                    </div>
                                    :
                                    <div className="d-grid col-md-2 mt-2">
                                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                    </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                }
                <Footer/>
            </main>
        </>
    )
}
export default CustomerEdit;